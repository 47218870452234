import { toMap } from "../utils/toMap";
import { isArray, isString, isUndefined, or } from "../utils/basicValidators";
import { createLocalStorageJsonDecoder } from "../utils/JsonLocalStorageDecoder";
import { IServerInfo } from "../ServerContext";
import {
  IRequestError,
  AuthenticationError,
  BadRequestError,
  ConnectionError,
  ForbiddenError,
  InvalidRequestError,
  InvalidResponseError,
  isRequestError,
  ServerError
} from "../errors/IRequestError";

const safeRequest = (
  promise: Promise<Response>
): Promise<Response | IRequestError> =>
  promise
    .then((response) => {
      switch (Math.floor(response.status / 100)) {
        case 2:
          return response;
        case 4:
          if (response.status === 400) {
            return new BadRequestError({ response });
          } else if (response.status === 401) {
            return new AuthenticationError({ response });
          } else if (response.status === 403) {
            return new ForbiddenError({ response });
          } else {
            return new InvalidRequestError({ response });
          }
        case 5:
          return new ServerError({ response });
        default:
          return new InvalidResponseError({ response });
      }
    })
    .catch((e) => {
      console.error(e);
      return new ConnectionError();
    });

const hasJson = (res: Response) =>
  res.headers.get("content-type")?.startsWith("application/json");

export const getServerInfo =
  (serverInfoPath: string) => (): Promise<IServerInfo | IRequestError> => {
    return safeRequest(fetch(serverInfoPath)).then(async (res) => {
      if (isRequestError(res)) {
        return res;
      } else if (hasJson(res)) {
        const data = await res.json();
        return data;
      }
      return new InvalidResponseError({ response: res });
    });
  };

export interface ILoginBody {
  username: string;
  password: string;
  service?: string;
}

export const loginMethods: (loginPath: string) => {
  login: (
    body: ILoginBody,
    options?: RequestInit
  ) => Promise<true | IRequestError>;
  loginByAccessToken: (
    credentials: Record<string, string | boolean>
  ) => Promise<true | IRequestError>;
  getCredentialsHistory: () => Promise<{
    lastUsername: string | undefined;
    lastService: string | undefined;
    allUsedServices: string[] | undefined;
  }>;
} = (loginPath: string) => {
  const savedLastUsedUsername = createLocalStorageJsonDecoder(
    `${PACKAGE_NAME}:lastUsedUser`,
    or(isUndefined, isString),
    undefined
  );

  const savedLastUsedService = createLocalStorageJsonDecoder(
    `${PACKAGE_NAME}:lastUsedService`,
    or(isUndefined, isString),
    undefined
  );

  const savedAllUsedServices = createLocalStorageJsonDecoder(
    `${PACKAGE_NAME}:allUsedServices`,
    isArray(isString),
    []
  );

  return {
    getCredentialsHistory: () => {
      return Promise.resolve({
        lastUsername: savedLastUsedUsername.read(),
        lastService: savedLastUsedService.read(),
        allUsedServices: savedAllUsedServices.read()
      });
    },
    login: async (body, options) => {
      const { password, username, service } = body;
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      if (isString(service)) formData.append("service", service);
      const loginResponse = fetch(loginPath, {
        method: "POST",
        body: formData,
        ...options
      });

      const result = await safeRequest(loginResponse);
      if (isRequestError(result)) return result;

      savedLastUsedUsername.write(username);
      if (service) {
        savedLastUsedService.write(service);
        const prevAll = new Set(savedAllUsedServices.read());
        savedAllUsedServices.write([service, ...prevAll]);
      }
      return true;
    },
    loginByAccessToken: async (credentials) => {
      const formData = new FormData();
      Object.entries(credentials).forEach(([key, value]) => {
        formData.append(key, `${value}`);
      });
      const res = await safeRequest(
        fetch(loginPath, {
          method: "POST",
          body: formData
        })
      );
      if (isRequestError(res)) {
        return res;
      } else {
        return true;
      }
    }
  };
};

export const logout =
  (logoutPath: string, getRequestHeaders: () => Promise<[string, string][]>) =>
  (): Promise<true | IRequestError> => {
    return getRequestHeaders()
      .then((headers) => safeRequest(fetch(logoutPath, { headers })))
      .then((res) => {
        if (isRequestError(res)) {
          return res;
        } else {
          return true;
        }
      });
  };

export interface IUserInfo {
  userId: string;
  emailVerified: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  picture: string;
}

export const getUserInfo =
  (
    userInfoPath: string,
    getRequestHeaders: () => Promise<[string, string][]>
  ) =>
  (): Promise<IUserInfo | IRequestError> => {
    return getRequestHeaders()
      .then((headers) => safeRequest(fetch(userInfoPath, { headers })))
      .then((res) => (isRequestError(res) ? res : res.json()));
  };

export const changeUserPassword =
  (path: string, getRequestHeaders: () => Promise<[string, string][]>) =>
  (
    currentPassword: string,
    newPassword: string,
    confirmation: string
  ): Promise<true | IRequestError> => {
    return getRequestHeaders()
      .then((headers) =>
        safeRequest(
          fetch(path, {
            method: "POST",
            headers: [["Content-Type", "application/json"], ...headers],
            body: JSON.stringify({
              currentPassword,
              newPassword,
              confirmation
            })
          })
        )
      )
      .then((res) => (isRequestError(res) ? res : true));
  };

export interface IPermission {
  scopes: string[];
  resourceId: string;
  entityId: string;
}

export const getUserPermissions =
  (
    userPermissionsPath: string,
    getRequestHeaders: () => Promise<[string, string][]>
  ) =>
  (): Promise<IPermission[]> => {
    return getRequestHeaders()
      .then((headers) => safeRequest(fetch(userPermissionsPath, { headers })))
      .then((res) => {
        if (isRequestError(res) || !hasJson(res)) {
          return [];
        } else {
          return res.json();
        }
      });
  };

export const getPermissionsByIds =
  (
    userPermissionsPath: string,
    getRequestHeaders: () => Promise<[string, string][]>
  ) =>
  (ids: string[]): Promise<IPermission[]> => {
    if (ids.length <= 0) {
      return Promise.resolve([]);
    }
    return getRequestHeaders()
      .then((headers) =>
        safeRequest(
          fetch(`${userPermissionsPath}?resources=${ids.join(";")}`, {
            headers
          })
        )
      )
      .then((res) => {
        if (isRequestError(res) || !hasJson(res)) {
          return [];
        } else {
          return res.json();
        }
      });
  };

export const getPermissionsByIdsMap = (
  userPermissionsPath: string,
  getRequestHeaders: () => Promise<[string, string][]>
): ((ids: string[]) => Promise<Map<string, string[]>>) => {
  const fetchByIds = getPermissionsByIds(
    userPermissionsPath,
    getRequestHeaders
  );
  return (ids) => {
    return fetchByIds(ids).then((permissions) =>
      toMap(
        permissions,
        (e) => e.entityId,
        (e) => e.scopes
      )
    );
  };
};

export const getPermittedScopes =
  (
    userPermissionsPath: string,
    getRequestHeaders: () => Promise<[string, string][]>
  ) =>
  async (scopes: string[]): Promise<string[]> => {
    const headers = await getRequestHeaders();
    return Promise.all(
      scopes.map((scope) =>
        safeRequest(
          fetch(`${userPermissionsPath}?resources=_${scope}&mode=decision`, {
            headers
          })
        ).then((res) => {
          if (isRequestError(res)) {
            return null;
          } else {
            return scope;
          }
        })
      )
    ).then((resolved) => resolved.filter(isString));
  };
