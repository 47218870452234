import "./set-public-path";
import { getPathDescriptions } from "./getPathDescriptions";
import * as auth from "./user/auth";
import * as ngsijs from "ngsijs";
import type { Connection } from "ngsijs";
import { bindApi } from "./ngsi/bindApi";
import { SharedNotificationSocket } from "./ngsi/SharedNotificationSocket";
import { UnauthorizedEmitter } from "./user/UnauthorizedEmitter";
import { getInitialServerContext, IServerContext } from "./ServerContext";
import { absorb } from "./utils/absorb";
import { keys } from "./utils/keys";
import { IRequestError, isRequestError } from "./errors/IRequestError";
import { createMetadataProviderNgsi } from "@netvision/entities-metadata-client";
import { loginMethods } from "./user/auth";

const GATEWAY_PATH = "/gateway";
const pathsDesc = getPathDescriptions(GATEWAY_PATH);

export const paths = Object.freeze(
  keys(pathsDesc).reduce((acc, key) => {
    acc[key] = pathsDesc[key].value;
    return acc;
  }, {} as Record<keyof typeof pathsDesc, string>)
);

let serverContext = getInitialServerContext(GATEWAY_PATH);

const getServerInfo = auth.getServerInfo(paths.serverInfo);

/**
 * Get server context
 */
export const getServerContext = absorb((): Promise<IServerContext> => {
  if (serverContext.service !== null) {
    return Promise.resolve({ ...serverContext });
  } else {
    return getServerInfo().then((info) => {
      if (isRequestError(info)) {
        console.error(info);
      } else {
        serverContext = { ...serverContext, ...info };
      }
      return { ...serverContext };
    });
  }
});

/**
 * Get fiware headers: 'fiware-service' and 'fiware-servicepath'
 */
export const getFiwareHeaders = (): Promise<[string, string][]> => {
  return getServerContext().then((context) => {
    return [
      ["fiware-service", context.service ?? ""],
      ["fiware-servicepath", ""]
    ];
  });
};

const protectedUrls = Object.values(pathsDesc)
  .filter((path) => path.protected)
  .map((path) => path.value);

const unauthorizedEmitter = new UnauthorizedEmitter(401, protectedUrls);
const badRequestEmitter = new UnauthorizedEmitter(400, protectedUrls);

export const addOnBadRequestListener: (func: () => void) => () => void =
  badRequestEmitter.addListener.bind(badRequestEmitter);

/**
 * Add on unauthorized listener
 */
export const addOnUnauthorizedListener: (func: () => void) => () => void =
  unauthorizedEmitter.addListener.bind(unauthorizedEmitter);

export const {
  /**
   * Login to system and obtain credentials
   */
  login,
  /**
   * Login to system by access token or code
   */
  loginByAccessToken,
  /**
   * Get saved history about used credentials
   */
  getCredentialsHistory
} = loginMethods(paths.login);

/**
 * Logout from system
 */
export const logout: () => Promise<true | IRequestError> = auth.logout(
  paths.logout,
  getFiwareHeaders
);

/**
 * Get logged user description
 */
export const getUserInfo: () => Promise<auth.IUserInfo | IRequestError> =
  auth.getUserInfo(paths.userInfo, getFiwareHeaders);

/**
 * Get logged user description
 */
export const changeUserPassword: (
  currentPassword: string,
  newPassword: string,
  confirmation: string
) => Promise<true | IRequestError> = auth.changeUserPassword(
  paths.changePassword,
  getFiwareHeaders
);

/**
 * Get logged user description
 */
export const getUserPermissions: () => Promise<auth.IPermission[]> =
  auth.getUserPermissions(paths.userPermissions, getFiwareHeaders);

/**
 * Get permissions
 */
export const getUserPermissionsByIds: (
  entityOrResourceIds: string[]
) => Promise<auth.IPermission[]> = auth.getPermissionsByIds(
  paths.userPermissions,
  getFiwareHeaders
);

/**
 * Get permissions
 */
export const getUserPermissionsByIdsMap: (
  entityOrResourceIds: string[]
) => Promise<Map<string, string[]>> = auth.getPermissionsByIdsMap(
  paths.userPermissions,
  getFiwareHeaders
);

/**
 * Get user's permitted scopes
 */
export const getUserPermittedScopes: (scopes: string[]) => Promise<string[]> =
  auth.getPermittedScopes(paths.userPermissions, getFiwareHeaders);

export type IServiceOptions = {
  service: string;
  servicepath: string;
};

const defaultEndpoint = `${window.location.origin}${paths.entities}`;

/**
 * Get ngsi connection
 */
export const getNgsiConnection = (
  serviceOptions?: IServiceOptions,
  endpoint = defaultEndpoint
): Promise<Connection> => {
  if (serviceOptions) {
    return Promise.resolve(new ngsijs.Connection(endpoint, serviceOptions));
  } else {
    return getServerContext().then(({ service }) => {
      if (service === null) {
        console.info("Service could not be resolved");
      }
      return new ngsijs.Connection(endpoint, { service: service ?? undefined });
    });
  }
};

/**
 * Ngsi methods that can be used right away with actual context
 */
export const {
  createEntity,
  listEntities,
  getEntity,
  getEntityAttributes,
  getEntityAttribute,
  getEntityAttributeValue,
  updateEntityAttributes,
  deleteEntity,
  batchQuery,
  batchUpdate
} = bindApi(getNgsiConnection);

const metadataProvider = createMetadataProviderNgsi({
  getEntities: (entities) => {
    return batchQuery({ entities }, { limit: 1000, keyValues: true })
      .then((res) => res.results)
      .catch((err) => {
        console.error("Batch query failed.", err);
        return [];
      });
  }
});

export const queryMetadata = metadataProvider.queryMetadata;

/**
 * Entities notification socket
 */
export const notificationSocket = new SharedNotificationSocket(
  paths.notificationSocket
);

export type { Connection, IEntity } from "ngsijs";
export type { IPermission, IUserInfo } from "./user/auth";
export type { IServerContext } from "./ServerContext";
export * from "./errors/IRequestError";
