export type ILicenseInfo = {
  certificateFile?: string;
  activationFile?: string;
  certificateExpirationUtc?: string;
  activationExpirationUtc?: string;
  isCertificateExpired?: boolean;
  isActivationExpired?: boolean;
  features?: unknown[];
}

export type IServerInfo = {
  service?: string;
  defaultService?: string;
  useMultiservice?: boolean;
  licenseInfoExpiration?: string;
  licenseInfo?: ILicenseInfo;
};

export interface IServerContext {
  gatewayPath: string;
  service: string | null;
  defaultService?: string;
  useMultiservice?: boolean;
  licenseInfoExpiration?: string;
  licenseInfo?: ILicenseInfo;
}

export const getInitialServerContext = (
  gatewayPath: string
): IServerContext => {
  return {
    gatewayPath: gatewayPath,
    service: null,
    defaultService: undefined,
    useMultiservice: undefined,
    licenseInfoExpiration: undefined,
    licenseInfo: undefined
  };
};
