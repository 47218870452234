import { keys } from "./utils/keys";

// protected -> requires valid cookie

const basePaths = {
  serverInfo: {
    value: "/serverInfo",
    protected: false
  },
  login: {
    value: "/auth/login",
    protected: false
  },
  userInfo: {
    value: "/auth/user/info",
    protected: true
  },
  changePassword: {
    value: "/auth/user/changePassword",
    protected: true
  },
  userPermissions: {
    value: "/auth/user/permissions",
    protected: true
  },
  logout: {
    value: "/auth/logout",
    protected: true
  },
  entities: {
    value: "/cameras",
    protected: true
  },
  vms: {
    value: "/vms",
    protected: true
  },
  files: {
    value: "/cameras/v1",
    protected: true
  },
  notificationSocket: {
    value: "/notifications/notification",
    protected: true
  },
  newEntities: {
    value: "/cameras/v2",
    protected: true
  },
};

type Paths = typeof basePaths;

export function getPathDescriptions(gatewayEndpoint: string): Paths {
  return keys(basePaths).reduce((acc, key) => {
    const path = basePaths[key];
    acc[key] = {
      ...path,
      value: gatewayEndpoint + path.value
    };
    return acc;
  }, {} as Paths);
}
